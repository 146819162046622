import React from 'react'
import moment from 'moment'
import './eventHeader.less'
const AlarmDetailHeader = Loader.loadBusinessComponent('MonitorHistoryAlarm', 'AlarmDetailHeader')

class KeyPersonHeader extends React.Component {
  render() {
    let { data = {}, handleText, operationDetail, handleOpenModal, saveText } = this.props
    return (
      <AlarmDetailHeader
        saveText={saveText}
        handleText={handleText}
        operationDetail={operationDetail}
        handleOpenModal={handleOpenModal}
        data={{ ...data, faceUrl: data.bodyUrl }}
        handleAuthName="eventHandle"
        isEvent={true}
        rightCenterP={
          <div className="center-message event-center-message">
            <p className="right-center-p" title={data.taskName}>
              <span className="right-center-span"> 所在布控任务：</span>
              {data.taskName || '-'}
            </p>
            <p className="right-center-p" title={data.deviceName}>
              <span className="right-center-span">告警设备名称：</span>
              {data.deviceName || '-'}
            </p>
            <p className="right-center-p" title={data.address}>
              <span className="right-center-span">告警设备地址：</span>
              {data.address || '-'}
            </p>
            <p className="right-center-p">
              <span className="right-center-span">告警时间：</span>
              {(data && moment(+data.captureTime).format('YYYY.MM.DD HH:mm:ss')) || '-'}
            </p>
          </div>
        }
      />
    )
  }
}

export default KeyPersonHeader
