import React from 'react';
import { message } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import EventHeader from './component/eventHeader';
import './index.less';

const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent');
const NoData = Loader.loadBaseComponent('NoData');
const PageDetails = Loader.loadBusinessComponent('PageDetails');
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap');
const Loading = Loader.Loading
// 魅影告警详情

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab', 'user', 'device')
@observer
class EventDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      oldData: undefined,
      handleVisible: false,
      operationDetail: '',
      detailList: [], // 外部告警列表
      libType: '',
      searchData: ''
    };
  }

  componentWillMount() {
    let { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    window.LM_DB.get('parameter', id).then(data => {
      this.setState({
        loading: true,
        libType: data.libType,
        searchData: data.searchData,
        isRealAlarm: data.isRealAlarm
      });
      return this.getDetail(id)
        .then(() => {
          this.getAlarmList();
          this.getDetailList(data.searchData, data.libType);
        })
        
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  getDetail = id => {
    const { libType, isRealAlarm = false } = this.state;
    return Service.alarmResult.alarmResults({ id, libType, isRealAlarm }).then(res => {
      let data = res.data;
      this.setState({
        loading: false,
        data,
        operationDetail: data.operationDetail
      });
      return data
    });
  };

  // 获取外部告警列表
  getDetailList = (searchData, libType) => {
    if (searchData.limit === 0) {
      searchData.limit = 200;
    }
    return Service.alarmResult
      .queryAlarmResults(searchData, libType)
      .then(res => {
        this.setState({
          detailList: res.data.list || []
        });
      });
  };

  //获取下方列表
  getAlarmList = () => {
    // 暂无列表
  };

  // 警情备注添加
  handleText = e => {
    const value = e.target.value;
    if (value.length > 200) {
      message.info('最大长度不超过200');
      return;
    }
    this.setState({
      operationDetail: value
    });
  };

  // 二次确认框
  handleOpenModal = type => {
    this.setState({
      type,
      handleVisible: true
    });
  };

  onModalCancel = () => {
    this.setState({
      handleVisible: false
    });
  };
  //保存备注
  saveText =() => {
    let { data, operationDetail, libType, isRealAlarm } = this.state;
    if(operationDetail === data.operationDetail) {
      return
    }
    Service.alarmResult.handleAlarmResult({
      id: data.id,
      operationDetail
    },
    {
      libType,
      isRealAlarm
    }).then(res => {
      message.success('保存备注成功')
      this.setState({
        data: res.data,
      })
    }).catch(err => message.error(err.data.message))
  }

  // 处理有效无效
  handleOk = () => {
    let {
      data,
      type,
      detailList,
      libType,
      oldData,
      isRealAlarm
    } = this.state;
    Service.alarmResult.handleAlarmResult(
      {
        id: data.id,
        isEffective: type
      },
      {
        libType,
        isRealAlarm
      }
    ).then(res => {
      this.setState({
        data: res.data,
        handleVisible: false
      });
      return res;
    }).then(res => {
      message.info('设置成功');
      SocketEmitter.emit(SocketEmitter.eventName.resolveAlarm, res.data);
      let nextDetail = undefined;
      if (detailList && detailList.length > 0) {
        let chose = {};
        if (oldData) {
          chose = detailList.find(v => v.id == oldData.id);
        } else {
          chose = detailList.find(v => v.id == data.id);
        }
        let number = detailList.indexOf(chose);
        if (number < detailList.length && number > -1) {
          nextDetail = detailList[number + 1];
        }
      }
      if (nextDetail) {
        this.changeDetailView(nextDetail.id);
      } else {
        this.getAlarmList();
      }
    });
  };
  
  // 更新地址栏url
  updatePage = (id) => {
    const { tab } = this.props;
    const { searchData, isRealAlarm, libType } = this.state;
    window.LM_DB.add('parameter', {
      id: id.toString(),
      libType,
      isRealAlarm,
      searchData
    }).then(() => {
      tab.goPage({
        moduleName: 'eventDetail',
        data: { id },
        action: 'replace',
        isUpdate: true
      })
    });
  }

  // 翻页
  changeDetailView = id => {
    this.updatePage(id);
    const { data } = this.state
    this.getDetail(id).then(() => {
      this.setState({
        oldData: data,
      });
      this.getAlarmList();
    });
  };

  renderContent() {
    let { data = {}, loading, detailList, operationDetail } = this.state;
    if (loading) {
      return null;
    }
    if (Object.keys(data).length === 0) {
      return <NoData />;
    }
    let dataIndex = detailList.findIndex(v => v.id === data.id),
      preData = undefined,
      nextData = undefined;
    if (dataIndex > 0) {
      preData = detailList[dataIndex - 1];
      nextData = detailList[dataIndex + 1];
    }
    if (dataIndex === 0 && detailList.length > 1) {
      nextData = detailList[dataIndex + 1];
    }
    return (
      <>
        <EventHeader
          data={data}
          saveText={this.saveText}
          handleOpenModal={this.handleOpenModal}
          handleText={this.handleText}
          operationDetail={operationDetail}
        />
        <div className="detail-imm">
          {preData ? (
            <PageDetails
              pageType="pre"
              imgUrl={preData.faceUrl}
              classNameNode='detail-text'
              id={preData.id}
              waterType="multiple"
              onChange={this.changeDetailView}
            />
          ) : (
            <div className="null" />
          )}
          <ImageMovieMap type="eventAlarm" data={data} key={data.id} />
          {nextData ? (
            <PageDetails
              pageType="next"
              imgUrl={nextData.faceUrl}
              classNameNode='detail-text'
              id={nextData.id}
              onChange={this.changeDetailView}
            />
          ) : (
            <div className="null" />
          )}
        </div>
      </>
    );
  }

  render() {
    let { handleVisible, type, loading } = this.state;
    return (
      <div className="event-detail">
        <Loading loading={loading}>{this.renderContent()}</Loading>
        <ConfirmComponent
          title={`${type == 1 ? '有' : '无'}效提醒确认`}
          visible={handleVisible}
          onCancel={this.onModalCancel}
          onOk={this.handleOk}
          width={320}
          icon={type == 1 ? 'icon-S_Photo_ThumbEffective' : 'icon-S_Photo_ThumbInvalid'}>
          <div>点击“确定”将其标注为{type == 1 ? '有' : '无'}效提醒？</div>
        </ConfirmComponent>
      </div>
    );
  }
}

export default EventDetail;
